<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: PREFIX + 's' }">{{$t('label_recommended')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{ action == 'editing'? $t('label_edit_' + PREFIX) : $t('label_add_' + PREFIX) }}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card
                    no-body
                    class="mb-0"
            >
                <!-- Header -->
                <div class="d-flex  mt-1 px-2 py-1">
                    <h5 class="mb-0">
                        {{ action == 'editing'? $t('label_edit_' + PREFIX) : $t('label_add_' + PREFIX) }}
                    </h5>
                </div>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >
                    <!-- Username -->
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_first_name')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_first_name')"
                                class="required"
                        >
                            <b-form-input

                                    v-model="itemData.firstName"
                                    :placeholder="$t('label_first_name')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_last_name')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_last_name')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.lastName"
                                    :placeholder="$t('label_last_name')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_phone')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_phone')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.phone"
                                    :placeholder="$t('label_phone')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_name_of_user')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_name_of_user')"
                        >
                            <b-form-input
                                    v-model="itemData.userName"
                                    :placeholder="$t('label_name_of_user')"
                                    :state="getValidationState(validationProps)"
                                    trim

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('Email')"
                            rules="required|email"
                            slim
                    >
                        <b-form-group
                                :label="$t('Email')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.emailAddress"
                                    :placeholder="$t('Email')"
                                    :state="getValidationState(validationProps)"
                                    trim

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            v-if="['admin', 'superadmin'].includes(userRole(authUser()))"
                            #default="validationProps"
                            :name="$t('label_gmail')"
                            rules="email"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_gmail')"
                        >
                            <b-form-input
                                    v-model="itemData.gmailAddress"
                                    :placeholder="$t('label_gmail')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_password')"
                            :rules="action == 'adding' ? 'required' : ''"
                            slim
                    >
                        <b-form-group

                                :label="$t('label_password')"
                                :class="action == 'adding' ? 'required' : ''"
                        >
                            <b-form-input
                                    v-model="itemData.userPassword "
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_password')"
                                    type="password"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_confirm_password')"
                            :rules="action == 'adding' ? 'required' : ''"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_confirm_password')"
                                :class="action == 'adding' ? 'required' : ''"
                        >
                            <b-form-input
                                    v-model="itemData.cPassword"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_confirm_password')"
                                    type="password"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_department')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_department')"
                                :state="getValidationState(validationProps)"
                        >
                            <div  class="d-flex align-items-center flex-wrap flex-md-nowrap">

                                <v-select
                                        class="form-control mb-1 mr-md-1"
                                        v-model="itemData.department_id"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="department_id"
                                        :clearable="false"
                                        :reduce="val => val.id"
                                        :placeholder="$t('label_select')"
                                        :options="departments"
                                >
                                    <template v-slot:option="option">
                                        {{option.name}}
                                    </template>
                                    <template v-slot:selected-option="option">
                                        {{option.name}}
                                    </template>
                                    <template v-slot:no-options="option">
                                        {{$t('label_no_items')}}
                                    </template>
                                </v-select>

                                <b-button @click="$bvModal.show('department-modal')" class=" mb-md-1" style=" white-space: nowrap;" size="sm" variant="outline-primary">{{$t('label_add') + ' ' + $t('label_department').toLowerCase()}}</b-button>

                            </div>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_position')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_position')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.position"
                                    :placeholder="$t('label_position')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                        <b-form-group
                                label=""
                                class="mt-2"
                        >
                            <b-form-checkbox
                                    id="egent-employee"
                                    variant="success"
                                    switch
                                    value="Y"
                                    unchecked-value="N"
                                    v-model="is_employee"
                            >
                                <label for="egent-employee">{{ $t('label_manager') }}</label>
                            </b-form-checkbox>
                        </b-form-group>


                    <validation-provider
                            v-if="is_employee == 'Y'"
                            #default="validationProps"
                            :name="$t('label_manager')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_manager')"
                                :state="getValidationState(validationProps)"
                        >

                            <infinity-scroll
                                    selected-prop="id"
                                    :multiple="false"
                                    url="/agent"
                                    :placeholder="$t('label_select')"
                                    :default-selection="chosenManager"
                                    v-model="itemData.parent_id"
                            >

                                <template #label="{item}">{{ item.name }}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                    </validation-provider>

                    <validation-provider
                            v-if="['admin', 'superadmin'].includes(userRole(authUser()))"
                            #default="validationProps"
                            :name="$t('label_keeper')"
                            slim
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_keeper')"
                                :state="getValidationState(validationProps)"
                                class="required"
                        >
                            <!--:close-on-select="false"-->
                            <infinity-scroll

                                    selected-prop="id"
                                    :multiple="false"
                                    url="/consultant"
                                    :placeholder="$t('label_keeper')"
                                    :default-selection="chosenConsultant"
                                    v-model="itemData.consultant_id"

                            >

                                <template #label="{item}">{{ item.name }}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_country')"
                            slim
                    >
                        <b-form-group :label="$t('label_country')">

                            <v-select
                                    v-model="itemData.country_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="countries"
                                    :reduce="val => val.idCountry"
                                    label="countryName"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"
                            >
                                <template v-slot:option="option">
                                    {{option.countryName}}
                                </template>
                                <template v-slot:selected-option="option">
                                    {{option.countryName}}
                                </template>
                            </v-select>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_town')"
                            slim
                    >
                        <b-form-group :label="$t('label_town')">
                            <b-form-input
                                    v-model="itemData.town"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_town')"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_street_and_house_number')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_street_and_house_number')"
                        >
                            <b-form-input
                                    v-model="itemData.street"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_street_and_house_number')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_post_code')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_post_code')"
                        >
                            <b-form-input
                                    v-model="itemData.zip"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_post_code')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <b-form-group
                            label=""
                            class="mt-2"
                    >
                        <b-form-checkbox
                                id="agent-company"
                                variant="success"
                                switch
                                value="Y"
                                unchecked-value="N"
                                v-model="itemData.company"
                        >
                            <label for="agent-company">{{ $t('label_company') }}</label>
                        </b-form-checkbox>
                    </b-form-group>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_company_name')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_company_name')"
                        >
                            <b-form-input
                                    v-model="itemData.company_name"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_company_name')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_nip')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_nip')"
                        >
                            <b-form-input
                                    v-model="itemData.NIP"
                                    trim
                                    :placeholder="$t('label_nip')"
                                    :state="getValidationState(validationProps)"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_krs')"
                            rules=""
                            slim
                    >
                        <b-form-group
                                :label="$t('label_krs')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.KRS"
                                    trim
                                    :placeholder="$t('label_krs')"
                                    :state="getValidationState(validationProps)"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_account_number')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_account_number')"
                        >
                            <b-form-input
                                    v-model="itemData.account_number"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_account_number')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_bank_name')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_bank_name')"
                        >
                            <b-form-input
                                    v-model="itemData.bank_name"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_bank_name')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_vat_payer')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_vat_payer')"
                        >

                           <div class="d-flex">
                               <b-form-radio
                                       class="mr-1"
                                       v-model="itemData.vat_payer"

                                       value="Y"
                               >
                                   <span style="white-space: nowrap;">{{$t('label_yes')}}</span>
                               </b-form-radio>
                               <b-form-radio
                                       class="mr-1"
                                       v-model="itemData.vat_payer"

                                       value="N"
                               >
                                   <span style="white-space: nowrap;">{{$t('label_no')}}</span>
                               </b-form-radio>
                           </div>

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider v-if="itemData.vat_payer == 'N'"

                            #default="validationProps"
                            name=""
                            :rules="itemData.company == 'Y' ? 'required' : '' "
                            slim
                    >
                        <b-form-group
                                label=""

                        >
                            <b-form-textarea
                                    rows="4"
                                    :state="getValidationState(validationProps)"
                                    v-model="itemData.vat_payer_txt"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_date_of_signing_agreement')"
                            class="flex-grow-1"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_date_of_signing_agreement')"
                                label-for="meeting_type_id"
                        >

                            <custom-date-picker
                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"
                                    :value="itemData.agreement_date"

                                    @input="itemData.agreement_date = $event"
                            >
                                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>

                            <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <!--*************************DYN BLOCK*********************************************************-->
                    <dynamic-items-block
                            class="mt-2"
                            alias="provision"
                            label="label_provision_general"
                            @deleteItemServer="deleteProvision($event, 'savedProvisions')"
                            :show-items-section.sync="showProvision1"
                            :blank-item="{provision_percent: '', provision_start: '', provision_end: ''}"
                            :items="savedProvisions"
                            :new-items.sync="new_provisions"
                    >
                        <template #button="{addBlankItem}">
                            <div class="mb-3">
                                <b-button @click.prevent="addBlankItem"
                                          class=" btn btn-success  ">
                                    {{$t('label_new')}}
                                </b-button>
                            </div>
                        </template>

                        <template #form="{item, ind, deleteItem}">
                            <button @click="deleteItem()"
                                    :style="{top: ind>0? 'calc(50% - 8px)' : 'calc(50% - 20px)'}"
                                    style=" position: absolute;;cursor:pointer; right: 0px;z-index: 10;"
                                    class="remove-procurator-item btn  "
                                    type="button">
                                <feather-icon color="red" title="Clear" data-toggle icon="XIcon" size="25"/>
                            </button>
                            <hr v-if="ind > 0" style="width:100%">
                            <b-col cols="12">

                                <validation-provider
                                        #default="validationProps"
                                        :key="ind"
                                        :name="$t('label_provision_general')"
                                        slim
                                        rules="required"
                                        :vid="'provision_general_percent_' + (item.id? '__' + item.id :  ind)"
                                >
                                    <b-form-group
                                            :label="$t('label_provision_general')"
                                            class="required"
                                    >
                                        <b-form-input
                                                :placeholder="$t('label_provision_general')"
                                                v-model="item.provision_percent"
                                                :state="getValidationState(validationProps)"
                                                trim
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" class="d-flex">
                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_date_from')"
                                        rules="required"
                                        class=" mr-2"
                                        slim
                                        :vid="'date_from_' + (item.id? '__' + item.id :  ind)"
                                >
                                    <b-form-group
                                            :label="$t('label_date_from')"
                                    >

                                        <custom-date-picker
                                                :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                                :locale="$store.state.appConfig.locale"
                                                :placeholder="$t('label_DD-MM-YYYY')"
                                                :value="item.provision_start"

                                                @input="item.provision_start = $event"
                                        >
                                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                            <div slot="label">
                                                <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                            </div>
                                        </custom-date-picker>

                                        <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_date_till')"
                                        rules="required"
                                        class="flex-grow-1"
                                        slim
                                        :vid="'date_to_' + (item.id? '__' + item.id :  ind)"
                                >
                                    <b-form-group
                                            :label="$t('label_date_till')"
                                    >

                                        <custom-date-picker
                                                :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                                :locale="$store.state.appConfig.locale"
                                                :placeholder="$t('label_DD-MM-YYYY')"
                                                :value="item.provision_end"
                                                @input="item.provision_end = $event"
                                        >
                                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                            <div slot="label">
                                                <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                            </div>
                                        </custom-date-picker>

                                        <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            </b-col>
                        </template>

                    </dynamic-items-block>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_id_of_partner')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_id_of_partner')"

                        >
                            <b-form-input
                                    v-model="itemData.id_of_partner"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_id_of_partner')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <div class="permissions-block mt-2 mb-2">

                        <p>{{$t('label_permissions')}}</p>

                        <b-form-group v-for="perm in userPermissions"
                                      label="" class="mt-1"
                        >
                            <b-form-checkbox
                                    :id="perm.alias"
                                    value="1"
                                    unchecked-value="0"
                                    v-model="itemData[perm.alias]"
                            >
                                <label :for="perm.alias">
                                    {{(perm.label.length == 1)? $t(perm.label[0]) : ($t(perm.label[0]) + ' (' + $t(perm.label[1]) + ')')}}
                                </label>
                            </b-form-checkbox>
                        </b-form-group>

                    </div>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_language')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_language')"
                                class="required"
                                :state="getValidationState(validationProps)"
                        >
                            <v-select
                                    v-model="itemData.language"
                                    :reduce="val => val.id"
                                    input-id="language"
                                    label="language"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"
                                    :options="languages"
                            >
                                <template v-slot:option="option">
                                    {{option.title}}
                                </template>
                                <template v-slot:selected-option="option">
                                    {{option.title}}
                                </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            class="mt-2"
                            #default="validationProps"
                            :name="$t('label_to_ocr')"
                            slim
                    >
                        <b-form-group
                                label=""
                        >
                            <b-form-checkbox
                                    id="agent-ocr"
                                    value="1"
                                    unchecked-value="0"
                                    v-model="itemData.ocr"
                            >
                                <label for="agent-ocr">{{ $t('label_to_ocr') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            class="mt-2"
                            :name="$t('label_document')"
                            slim
                            #default="validationProps"

                    >

                        <file-input

                                v-model="document"
                                class="file--uploader drop-block mb-3"
                                :max-files-count='10'
                                :required="true"
                                :multiple="true"
                                allowed-extensions="*"
                                :max-file-size='100'
                                :url="''"
                        >
                            <template #uploadBody>
                                <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                                <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                {{$t('label_add_file')}}
                            </template>

                            <template #errorBug="fileErrors">

                                <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                                <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </template>
                        </file-input>

                    </validation-provider>



                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_edit') : $t('label_add') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                v-on:click="$router.go(-1)"
                        >
                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>
        <department-modal  @item-added="departments.unshift($event); itemData.department_id = $event.id"></department-modal>
    </div>
</template>

<script>

    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import departmentModal from './../department/modals/processItemModal'
    import  fileInput from '@/views/components/fileUploadInput'
    import {
        BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox, BFormRadio
    } from 'bootstrap-vue'
    import {MODULE_PREFIX as PREFIX, DEPARTMENT_PREFIX, LANGUAGES_PREFIX} from './moduleHelper'
    import {userPermissions, defaultLanguageId, defaultCountryId} from './../pagesHelper'
    import vSelect from 'vue-select'
    import  infinityScroll from '@/views/components/infinityScroll'
    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import {serialize} from 'object-to-formdata';

    export default {

        components: {

            BSidebar,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            BFormCheckbox,
            BFormRadio,
            BFormTextarea,

            ValidationProvider,
            ValidationObserver,
            vSelect,
            infinityScroll,
            fileInput,
            dynamicItemsBlock,
            departmentModal
        },
        data() {
            return {

                DEPARTMENT_PREFIX,
                LANGUAGES_PREFIX,
                PREFIX,

                itemData: {},

                action: 'adding',

                blankItemData: {

                    id:null,
                    firstName: '',
                    lastName: '',
                    userName: '',
                    phone: '',
                    emailAddress: '',
                    gmailAddress: '',
                    userPassword: '',
                    cPassword: '',
                    consultant_id: null,
                    department_id: null,
                    position:'',
                    parent_id: 0,
                    country_id: defaultCountryId,
                    bank_name: '',
                    account_number: '',
                    town: '',
                    street: '',
                    zip: '',
                    vat_payer_txt: this.$t('label_vat_payer_txt'),
                    company: '',
                    company_name: '',
                    NIP: '',
                    KRS: '',
                    vat_payer: 'N',
                    agreement_date: '',
                    id_of_partner: '',
                    ocr: '',
                    language: defaultLanguageId,

                },

                is_employee: '',
                showProvision1: true,

                document: [],

                new_provisions:[],

                savedProvisions: [],

                countries: [],
                departments: [],
                languages: [],
                userPermissions,

                chosenManager: false,
                chosenConsultant: false,
            }
        },

        created() {

            this.blankItemData.id = this.$router.currentRoute.params.id;
            for(let i = 0; i < userPermissions.length; i++) {
                this.blankItemData[userPermissions[i].alias] = 0;
            }
            this.initData();

        },
        methods: {

            initData() {

                if (this.blankItemData.id > 0) {
                    this.action = 'editing';
                    this.async('get', '/' + this.PREFIX + '/' + this.blankItemData.id, {}, function (resp) {

                        let editedItem = resp.data;
                        let item = Object.assign({}, this.blankItemData);
                        item.id = editedItem.id;
                        for (let prop in item) {
                            if (editedItem.hasOwnProperty(prop)) {
                                item[prop] = editedItem[prop];
                            }
                        }
                        for (let prop in item) {
                            if (prop != 'id' && editedItem[this.PREFIX+'_data'].hasOwnProperty(prop)) {
                                item[prop] = editedItem[this.PREFIX+'_data'][prop];
                            }
                        }

                        let savedProvisions = editedItem.agent_data? editedItem.agent_data.provision_general : [];

                        if(savedProvisions.length > 0) {
                            for (let i = 0; i < savedProvisions.length; i++) {
                                savedProvisions[i]['provision_start'] = formatDate(savedProvisions[i]['provision_start'], 'DD/MM/YYYY', 'unix');
                                savedProvisions[i]['provision_end'] = formatDate(savedProvisions[i]['provision_end'], 'DD/MM/YYYY', 'unix');
                            }
                            this.showProvision1 = true;
                        }

                        this.savedProvisions = savedProvisions;

                        this.chosenManager = editedItem.manager;
                        this.chosenConsultant = editedItem.agent_data? editedItem.agent_data.consultant : false;
                        item.userPassword = '';
                        item.cPassword = '';
                        this.is_employee = (parseInt(item.parent_id) > 0)? 'Y' : 'N';

                            this.itemData = item;
                    });
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);
                }

                this.async('get', '/countries', {}, (res) => {this.countries = res.data});
                this.async('get', '/' + this.DEPARTMENT_PREFIX + 's', {}, function (resp) {
                    this.departments = resp.data.departments;
                });
                this.async('get', '/' + this.LANGUAGES_PREFIX, {}, function (resp) {
                    // console.log(resp);
                    this.languages = resp.data;
                });
            },

            onSubmit() {

                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);

                if (this.document.length > 0) {
                        formData.append('document[]', this.document[0]);
                }

                for(let i = 0; i < this.savedProvisions.length; i++) {
                    formData.append('provision_general_percent[]', this.savedProvisions[i].provision_percent);
                    formData.append('provision_general_start[]', this.savedProvisions[i].provision_start);
                    formData.append('provision_general_end[]', this.savedProvisions[i].provision_end);
                }
                for(let i = 0; i < this.new_provisions.length; i++) {
                    formData.append('provision_general_percent[]', this.new_provisions[i].provision_percent);
                    formData.append('provision_general_start[]', this.new_provisions[i].provision_start);
                    formData.append('provision_general_end[]', this.new_provisions[i].provision_end);
                }


                if (this.action == 'adding') {
                    this.async('post', '/' + this.PREFIX, formData, function (resp) {

                        this.$router.push({name: this.PREFIX + '-details', params:{id: resp.data.item.id}});

                    }, false, {headers:{'Content-Type': 'multipart/form-data'}});
                } else {

                    this.async('put', '/' + this.PREFIX + '/' + this.itemData.id, formData, function (resp) {
                        this.$router.push({name: this.PREFIX + '-details', params:{id: this.itemData.id}});
                    }, false, {headers:{'Content-Type': 'multipart/form-data'}});
                }
            },

            deleteProvision(prov, provName){
                this[provName] = this[provName].filter(function(item){return item.id != prov.id});
            }

        }
    }
</script>
